
#contenedor-tech{
    padding-left: 8%;
    padding-right: 8%;
}
#row1{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
}
#row2{
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    text-align:center;
    width: 100%;
}
.client-text{
    margin-top: 5% !important;
}
#column1{
    
    width: 20%;
}
#column2{
    width: 50%;
}