#titulo{
    text-align: center;
    padding-top:2%;
}
#descripcion{
    text-align: center;
}
#row{
    display: flex;
    width: 100%;
    
}
#column{
    width: 33%;
}
#contenedor-nosotros{
    background-color: #f2f2f2;
    padding:3%;
    padding-right: 14%;
    padding-left: 14%;
    text-align: center;
}
#container-text{
    padding-left: 25%;
    padding-right: 25%;
}
#container-card{
    margin-top: 30%;
    
    position: relative;
    text-align: center;
    
    background-color: black;
    margin-left: 9%;
    margin-right: 9%;
    
}


#card-text{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    color: white;
}