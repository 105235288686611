.image{
  width: 100%;
  max-width: 2000px;
  height: 600px;
  min-height: 300px;
}
#white-text{
  color: white;
  
}
#container{
    position: relative;
    text-align: center;
    color: white;
    position:"fixed";
    
}
#hr{
    border: 1px solid white;
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}
#header-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}