.rowp{
    display: flex;
    width: 100%;    
}


.text-card-tech{
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 5%;
}
.column-2{
    width: 50%;   
    padding: 2%;
}

.img{
    align-items: center;
    text-align:center;
    user-select: none;
    pointer-events: none;
}
.carousel {
    position: relative;
  }
  
  .carousel .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel .dot.active {
    background-color: #555;
  }
  
  .carousel .dot-container {
    position: absolute;
    padding-top: 10px;
    
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  
  .button {
    background-color: #555;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }